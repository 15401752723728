$(() => {
	const MODAL = {
		init() {
			const $modalBtn = $('.js-modal-btn');
			$modalBtn.on('click', (e) => {
				e.preventDefault();
				const $this = $(e.currentTarget);
				const $modal = $('.js-modal').filter(`[data-modal="${$this.data('target')}"]`);
				$modal.addClass('modal-show');
				$('body').addClass('body-freeze');
			});

			const $modalClose = $('.js-modal-close');
			$modalClose.on('click', (e) => {
				e.preventDefault();
				$(e.currentTarget).closest('.js-modal').removeClass('modal-show');
				$('body').removeClass('body-freeze');
			});
		},
	};

	MODAL.init();


	const FORM = {
		init() {
			const $form = $('.js-form');
			$form.on('submit', (e) => {
				e.preventDefault();
				const $this = $(e.currentTarget);
				const $submit = $this.find('[type="submit"]');
				const $successShow = $this.find('.js-form-success-show');
				const $successHide = $this.find('.js-form-success-hide');
				const data = new FormData($this.get(0));
				const submitText = $submit.text();

				$submit.text('Отправляю...');
				$submit.attr('disabled', 'disabled');

				$.ajax({
					url: $this.attr('action'),
					type: $this.attr('method'),
					data,
					cache: false,
					processData: false,
					contentType: false,
				}).done(() => {
					$successHide.slideUp();
					$successShow.slideDown();

					/* eslint-disable */
					// if ($this.data('goal')) {
					// 	yaCounter18234274.reachGoal($this.data('goal'));
					// }
					/* eslint-enable */
				}).fail((res) => {
					const message = Object
						.keys(res.responseJSON)
						.map(el => `${el}: ${res.responseJSON[el]}`)
						.join('\n');
					window.alert(`Исправьте ошибки:\n ${message}`);
				}).always(() => {
					$submit.removeAttr('disabled');
					$submit.text(submitText);
				});

				return false;
			});
		}
	};

	FORM.init();
});
