// smooth scroll

	$('a[href*="#"]')
		.click(e => {
			if (location.hostname === e.currentTarget.hostname) {
				let target = $(e.currentTarget.hash);
				target = target.length ? target : $('[id=' + e.currentTarget.hash.slice(1) + ']');

				if (target.length) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top
					}, 1000, () => {
						const $target = $(target);
						$target.focus();

						if ($target.is(':focus')) {
							return false;
						}

						$target.attr('tabindex', '-1');
						$target.focus();
					});
				}
			}
		});
