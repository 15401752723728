$(() => {
	const SLIDER = {
		$slider: $('.js-slider'),
		$slides: undefined,
		$switcher: undefined,
		slides: [],
		idx: 0,

		init() {
			if (!this.$slider) {
				console.warn('Determine .js-slider element');
				return false;
			}

			this.$slides = this.$slider.find('.js-slides');
			this.slides = this.$slides.find('> *');
			this.$switcher = this.$slider.find('.js-switcher');

			if (!this.slides.length) {
				console.warn('Fill .js-slider with elements');
				return false;
			}


			// Events

			this.$switcher.on('click', (e) => {
				e.preventDefault();
				this.moveTo(e.target.getAttribute('data-idx'));
			});

			let autoPlayTimer = setInterval(this.moveNext.bind(this), 5000);

			this.$slider.on('mouseover', () => {
				clearInterval(autoPlayTimer);
			});

			this.$slider.on('mouseout', () => {
				autoPlayTimer = setInterval(this.moveNext.bind(this), 5000);
			});

			return true;

		},

		moveTo(index) {
			const idx = parseInt(index, 10);
			if (idx === this.idx) {
				return;
			}

			const prevSlide = this.$slides.find('.about__slide-active');
			prevSlide.addClass('about__slide-close');
			setTimeout(() => {
				prevSlide.removeClass('about__slide-active');
				prevSlide.removeClass('about__slide-close');

				const nextSlide = $(this.slides[idx]);
				nextSlide.removeClass('about__slide-close');
				nextSlide.addClass('about__slide-show about__slide-active');
				setTimeout(() => {
					nextSlide.removeClass('about__slide-show');
				}, 500);
			}, 500);


			this.$switcher.removeClass('about__stripe-active');
			this.$switcher.eq(idx).addClass('about__stripe-active');
			this.idx = idx;
		},

		moveNext() {
			if (this.idx + 1 >= this.slides.length) {
				this.moveTo(0);
			} else {
				this.moveTo(this.idx + 1);
			}
		},
	};

	SLIDER.init();
});
