// $(() => {
// 	$(document).ready(function(){
// 		$('.mobile__flex').slick({
// 			centerMode: true,
// 			centerPadding: '50px',
// 			focusOnSelect: true,
// 			arrows: false,
// 			slidesToShow: 4
//
// 		});
// 	});
//
// });
