$(() => {
    var MODAL = {
		init: function init() {
			var $modalBtn = $('.js-modal-btn');
			$modalBtn.on('click', function (e) {
				e.preventDefault();
				var $this = $(e.currentTarget);
				var $modal = $('.js-modal').filter('[data-modal="' + $this.data('target') + '"]');
				$modal.addClass('modal-show');
				$('body').addClass('body-freeze');
			});

			var $modalClose = $('.js-modal-close');
			$modalClose.on('click', function (e) {
				e.preventDefault();
				$(e.currentTarget).closest('.js-modal').removeClass('modal-show');
				$('body').removeClass('body-freeze');
			});
		}
	};
});
