$(() => {
	const ONLOAD = {
		init() {
			$(document).ready(function(){
				$('.js-overlap').addClass('overlap-hover');
			});
		},
	};

	ONLOAD.init();
});
